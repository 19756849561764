import { DialogCloseResult, DialogController } from 'aurelia-dialog';
import { autoinject } from 'aurelia-framework';
import { PubSub } from 'lib/event/PubSub';
import { InvoiceLineUpdateDto, ServiceInvoiceDetails, ServiceInvoiceLinesUpdateDto } from 'models';
import { ErrorService } from 'services/error-service';
import { ServiceInvoiceService } from 'services/service-invoice-service';
import { ServiceService } from 'services/service-service';
import { SystemFileStorageService } from 'services/system-file-storage-service';
import { ToastService } from 'services/toast-service';
import './service-invoice-preview.scss';

@autoinject
export class ServiceInvoicePreview {
  protected invoices: ServiceInvoiceDetails[];
  protected invoiceIds: number[];
  protected approved: boolean;

  hiddenTable = {};

  protected generateFilesDisabled = true;
  protected someMissDateOrRef = false;

  constructor(
    private dialogController: DialogController,
    private serviceInvoiceSerivce: ServiceInvoiceService,
    private systemFileStorageService: SystemFileStorageService,
    private pubsub: PubSub,
    private toaster: ToastService,
    private errorService: ErrorService,
    protected serviceService: ServiceService
  ) {}

  private async getInvoiceLines() {
    try {
      this.invoices = await this.serviceInvoiceSerivce.getInvoiceLinesByInvoiceIds(this.invoiceIds);
      this.approved = this.invoices[0].ApprovedById ? true : false;
      this.checkIfCanGenerateFiles();
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  protected checkIfCanGenerateFiles() {
    const someMissFile = this.invoices.some((f) => !f.InvoiceSystemFileId);
    const someMissDateOrRef = this.invoices.find((i) => {
      return i.InvoiceLines.some((f) => {
        return !f.YourRef || !f.OrderDate;
      });
    });

    // If some miss date or ref we can't generate files
    if (someMissDateOrRef) {
      this.generateFilesDisabled = true;
      this.someMissDateOrRef = true;
      return;
    } else {
      this.someMissDateOrRef = false;
    }

    // If some miss files, but all have ref and date we can generate files
    if (someMissFile) {
      this.generateFilesDisabled = false;
      return;
    }
  }

  protected activate(model: number[]) {
    this.invoiceIds = model;
    void this.getInvoiceLines();
  }

  protected hideTable(key: string) {
    this.hiddenTable[key] = !this.hiddenTable[key];
    this.hiddenTable = { ...this.hiddenTable };
  }
  protected hideAllTable() {
    if (this.hiddenTable['all']) {
      this.hiddenTable = {};
      return;
    } else {
      this.hiddenTable = this.invoices.reduce((acc, curr) => {
        acc[curr.Id] = true;
        return acc;
      }, {});
      this.hiddenTable['all'] = true;
    }
  }

  // Aurelia event
  protected canDeactivate(dialogResult: DialogCloseResult) {
    if (dialogResult.output === 'force') {
      return true;
    }
  }

  protected resetSelectedForEdit(invoiceId: number) {
    this.selectedForEdit[invoiceId] = {};
  }

  protected async downloadFile(fileId: number) {
    try {
      await this.systemFileStorageService.download(fileId, true);
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  protected doUpdateRows() {
    for (const invoice of this.invoices) {
      for (const line of invoice.InvoiceLines) {
        line.YourRef = invoice.YourRef;
        line.OrderDate = invoice.OrderDate?.toISOString?.();
        line.MarkedWith = invoice.MarkedWith;
      }
    }
    this.checkIfCanGenerateFiles();
  }

  protected selectedForEdit: {
    [key: number]: {
      [key: string | number | 'all' | 'count']: boolean | number;
    };
  } = {};

  protected handleSelectForEdit(invoiceId: number, id: number | 'all') {
    setTimeout(() => {
      if (!this.selectedForEdit[invoiceId]) {
        this.selectedForEdit[invoiceId] = {
          all: false,
        };
      }
      if (id === 'all') {
        const currentAll = this.selectedForEdit[invoiceId]['all'];
        this.selectedForEdit[invoiceId]['all'] = !currentAll;
        this.invoices
          .find((i) => i.Id == invoiceId)
          .InvoiceLines.forEach((f) => (this.selectedForEdit[invoiceId][f.Id] = !currentAll));
      } else {
        this.selectedForEdit[invoiceId][id] = !this.selectedForEdit[invoiceId][id];
      }

      this.selectedForEdit[invoiceId]['count'] = Object.keys(this.selectedForEdit[invoiceId]).filter(
        (f) => f !== 'all' && f !== 'count' && this.selectedForEdit[invoiceId][f]
      ).length;
    });
  }

  protected async onGenerateFiles() {
    try {
      this.doUpdateRows();

      const lines = this.invoices
        // Remove invoices that already have a file
        .filter((f) => !f.InvoiceSystemFileId)
        .map((invoice) => {
          this.resetSelectedForEdit(invoice.Id);

          const dto = new ServiceInvoiceLinesUpdateDto();
          dto.InvoiceId = invoice.Id;
          dto.InvoiceLinesEdits = invoice.InvoiceLines.map((line) => {
            const date = new Date(line.OrderDate);
            date.setHours(12, 0, 0, 0);

            const lineDto = new InvoiceLineUpdateDto();
            lineDto.LineId = line.Id;
            lineDto.YourRef = line.YourRef;
            lineDto.OrderDate = date;
            lineDto.MarkedWith = line.MarkedWith;
            return lineDto;
          });
          return dto;
        });

      await this.serviceInvoiceSerivce.generateInvoiceFiles(lines);
      await this.getInvoiceLines();
      this.toaster.showSuccess('general.filesGenerated');
      this.pubsub.publish('invoice-result-generated', null);
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  protected async approve() {
    try {
      await this.serviceInvoiceSerivce.approve(this.invoices[0].Id);
      await this.getInvoiceLines();
      this.toaster.showSuccess('general.approved')
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  protected async save() {
    try {
      this.doUpdateRows();

      const lines = this.invoices
      // Remove invoices that already have a file
      .filter((f) => !f.InvoiceSystemFileId)
      .map((invoice) => {
        this.resetSelectedForEdit(invoice.Id);

        const dto = new ServiceInvoiceLinesUpdateDto();
        dto.InvoiceId = invoice.Id;
        dto.InvoiceLinesEdits = invoice.InvoiceLines.map((line) => {
          const date = new Date(line.OrderDate);
          date.setHours(12, 0, 0, 0);

          const lineDto = new InvoiceLineUpdateDto();
          lineDto.LineId = line.Id;
          lineDto.YourRef = line.YourRef;
          lineDto.OrderDate = date;
          lineDto.MarkedWith = line.MarkedWith;
          return lineDto;
        });
        return dto;
      });

      await this.serviceInvoiceSerivce.update(lines, lines[0].InvoiceId);
      await this.getInvoiceLines();
      this.toaster.showSuccess('general.orderSaved');

    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  protected onClose() {
    void this.dialogController.cancel();
  }
}
