import { DialogService } from 'aurelia-dialog';
import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-framework';
import { Models } from 'models/core';
import { CustomerService } from 'services/customer-service';
import { ErrorService } from 'services/error-service';
import { MeshSizeService } from 'services/mesh-size-service';
import { ServicePriorityService } from 'services/service-priority-service';
import { ServiceStationService } from 'services/service-station-service';
import { ServiceStatusService } from 'services/service-status-service';
import { SiteService } from 'services/site-service';
import { ServiceStationStorageService } from '../../services/service-station-storage-service';
import { NetTypeService } from '../../services/net-type-service';
import { NetShapeService } from 'services/net-shape-service';
import { InvoiceableService, ServiceToInvoice } from 'models';
import { ServiceInvoiceService } from 'services/service-invoice-service';
import { ServiceInvoicePreview } from './service-invoice-preview';
import { PubSub } from 'lib/event/PubSub';
import { ToastService } from 'services/toast-service';
import { Prompt } from 'elements/prompt';
import { I18N } from 'aurelia-i18n';
import { PaginationHandler } from '../../elements/pagination-handler';
import { DataFilter } from 'lib/tables/DataFilter';
import { getCallbackData } from 'elements/filterer';
import { Filters, filterTypes } from 'elements/Filter';
import { ServiceDialog } from 'components/service-dialog/service-dialog';
import { TableHeader } from 'components/table/table-content';

type InvoiceableSelection = {
  [key: string]: {
    Washing: boolean;
    Waste: boolean;
    Testing: boolean;
    Reparation: boolean;
    Impregnation: boolean;
    Spagetti: boolean;
    Packaging: boolean;
    Delivery: boolean;
    All: boolean;
  };
};

type TableFilter = {
  serviceStationId?: number;
  customerIds?: string[];
} & DataFilter;

@autoinject
export class ServiceInvoiceable {
  protected canInvoice = false;
  protected invoiceable: InvoiceableService[] = [];

  protected dataFilter: TableFilter = new DataFilter();

  protected selectedForInvoice: InvoiceableSelection = {};
  protected tableHeaders: TableHeader<InvoiceableService & { actions: '' }>[] = [
    { key: 'actions', label: '', sortable: false },
    { key: 'Id', label: 'general.id', sortable: true },
    { key: 'NetIdentifier', label: 'net.netidentifier', sortable: true },
    { key: 'CustomerName', label: 'general.customer', sortable: true },
    { key: 'ServiceStationName', label: 'general.servicestation', sortable: true },
    { key: 'ReceivedDate', label: 'service.receivedDate', sortable: true },
    { key: 'WashingToInvoice', label: 'service.wash', sortable: true },
    { key: 'TestingToInvoice', label: 'service.test', sortable: true },
    { key: 'RepairToInvoice', label: 'service.reparation', sortable: true },
    { key: 'AntifoulingToInvoice', label: 'service.impregnation', sortable: true },
    { key: 'SpaghettiToInvoice', label: 'service.spagetti', sortable: true },
    { key: 'PackagingToInvoice', label: 'service.packaging', sortable: true },
    { key: 'DeliveryToInvoice', label: 'service.delivery', sortable: true },
  ];

  constructor(
    private dialogService: DialogService,
    private pubsub: PubSub,
    private serviceInvoiceService: ServiceInvoiceService,
    private errorService: ErrorService,
    private toaster: ToastService,
    private i18n: I18N,
    private pagination: PaginationHandler,
    private router: Router,
    protected siteService: SiteService,
    protected serviceStationStorageService: ServiceStationStorageService,
    protected netTypeService: NetTypeService,
    protected netShapeService: NetShapeService,
    protected serviceStationService: ServiceStationService,
    protected servicePriorityService: ServicePriorityService,
    protected customerService: CustomerService,
    protected meshSizeService: MeshSizeService,
    protected serviceStatusService: ServiceStatusService
  ) {}

  protected bind() {
    this.pubsub.sub('invoice-result-revoked', () => {
      void this.getServices();
    });

    this.pubsub.sub('filter-section:search-text-changed', (data) => {
      if (data.context !== this.context) return;
      this.dataFilter.searchText = data.searchText;
      void this.getServices();
    });

    this.pubsub.sub('filter-section:list-settings-changed', (data) => {
      if(this.dataFilter.top === data.settings.pageSize) return;

      this.dataFilter.top = data.settings.pageSize;
      this.pagination.pageSize = data.settings.pageSize;
      this.pagination.currentPage = 1;
      void this.getServices();
    })

    this.pubsub.sub('filter-section:reset-filters', (data) => {
      if (data.context !== this.context) return;
      this.filterClearAll();
    });

    this.pubsub.sub('export-list', (data) => {
      if (data === 'service-invoiceable') {
        void this.getServices({ export: true });
      }
    });
  }

  protected setupPagination() {
    const routeParams = this.router.currentInstruction?.queryParams;
    let currentPage = 1;
    if (routeParams && routeParams.currentPage) {
      currentPage = +routeParams.currentPage;
    }

    this.pagination.init({
      currentPage: currentPage,
      totalItems: 0,
      onPageChanged: (_, pagination) => {
        this.dataFilter.skip = pagination.skip;
        this.dataFilter.top = pagination.top;
        void this.getServices();
      },
    });

  }

  protected attached() {
    this.setupPagination();
  }

  protected setCanInvoice() {
    this.canInvoice = Object?.values(this.selectedForInvoice)?.some((x) => {
      if (!x) return false;
      return Object?.values?.(x).some((y) => y);
    });
  }

  protected handleInvoiceFieldSelection(row: Models.Service, field: string | 'All') {
    const invoiceableService = this.invoiceable.find((x) => x.Id === row.Id);
    setTimeout(() => {
      if (!this.selectedForInvoice[row.Id]) {
        this.selectedForInvoice[row.Id] = {
          Washing: false,
          Waste: false,
          Testing: false,
          Reparation: false,
          Impregnation: false,
          Spagetti: false,
          Packaging: false,
          Delivery: false,
          All: false,
        };
      }

      if (field === 'All') {
        if (this.selectedForInvoice[row.Id].All) {
          this.selectedForInvoice[row.Id] = {
            Washing: false,
            Waste: false,
            Testing: false,
            Reparation: false,
            Impregnation: false,
            Spagetti: false,
            Packaging: false,
            Delivery: false,
            All: false,
          };
        } else {
          this.selectedForInvoice[row.Id] = {
            Washing: invoiceableService.WashingToInvoice,
            Waste: invoiceableService.WashingToInvoice,
            Testing: invoiceableService.TestingToInvoice,
            Reparation: invoiceableService.RepairToInvoice,
            Impregnation: invoiceableService.AntifoulingToInvoice,
            Spagetti: invoiceableService.SpaghettiToInvoice,
            Packaging: invoiceableService.PackagingToInvoice,
            Delivery: invoiceableService.DeliveryToInvoice,
            All: true,
          };
        }
      } else {
        this.selectedForInvoice[row.Id][field] = !this.selectedForInvoice[row.Id][field];
        if (Object.entries(this.selectedForInvoice[row.Id]).find(([key, value]) => key !== 'All' && value == false)) {
          this.selectedForInvoice[row.Id].All = false;
        }
      }
      this.setCanInvoice();
    });
  }

  protected setOrderByColumn(column: string) {
    this.dataFilter.setOrderByAndFlipOrderDirectionIfSameKey(column);
    void this.getServices();
  }

  protected async getServices(props?: { export?: boolean }) {
    try {
      const filters = await this.filterGetFiltersQuery?.();
      this.dataFilter.export = props?.export || false;
      this.dataFilter.serviceStationId = filters?.serviceStationId || null;
      this.dataFilter.customerIds = filters?.customerIds || [];
      this.dataFilter.locale = this.i18n.getLocale() as 'en' | 'nb' | 'es';

      const response = await this.serviceInvoiceService.invoiceable(this.dataFilter);
      if (!response) return;
      this.pagination.totalItems = +response.headers.get('x-total-count') || 0;
      this.invoiceable = response.data;
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  protected openDialog(data: number[]) {
    if (!data?.length) {
      return;
    }
    this.dialogService
      .open({
        viewModel: ServiceInvoicePreview,
        model: data,
        lock: true,
        position: () => {},
      })
      .whenClosed(() => {
        document.querySelector('html').style.overflowY = null;
      })
      .catch(() => {
        document.querySelector('html').style.overflowY = null;
      });
  }

  protected async confirmCreateInvoice() {
    let create = false;
    await this.dialogService
      .open({
        viewModel: Prompt,
        model: {
          header: 'general.createOrder',
          message: this.i18n.tr('general.createOrderConfirmation'),
          messagePreparsed: true,
          actions: {
            continue: { enabled: true, t: 'dialog.yes' },
            cancel: { enabled: true, t: 'dialog.cancel' },
          },
        },
      })
      .whenClosed((res) => {
        if (res.output === 'continue') {
          create = true;
        }
      });
    return create;
  }

  protected async startInvoicing() {
    try {
      const shouldCreate = await this.confirmCreateInvoice();
      if (!shouldCreate) return;
      const selectedForInvoice = Object.entries(this.selectedForInvoice)
        .filter(([, value]) => {
          if (!value) return;
          // Return true if any of the values are true
          return Object.values(value).some((x) => x);
        })
        .map(([serviceId, row]) => {
          const toInvoice = new ServiceToInvoice();
          toInvoice.ServiceId = +serviceId;
          toInvoice.Washing = row.Washing;
          toInvoice.Waste = row.Waste;
          toInvoice.Testing = row.Testing;
          toInvoice.Reparation = row.Reparation;
          toInvoice.Impregnation = row.Impregnation;
          toInvoice.Spagetti = row.Spagetti;
          toInvoice.Packaging = row.Packaging;
          toInvoice.Delivery = row.Delivery;
          return toInvoice;
        });

      const ids = await this.serviceInvoiceService.invoice(selectedForInvoice);
      this.toaster.showSuccess('general.orderlinesGenerated');

      this.openDialog(ids);

      await this.getServices();

      this.pubsub.publish('invoice-result-generated', null);
      this.selectedForInvoice = {};
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  protected openService(id: number, netId: number) {
    this.dialogService
      .open({
        viewModel: ServiceDialog,
        model: { Id: id, NetId: netId },
        lock: false,
        position: () => {},
      })
      .whenClosed(() => {
        void this.getServices();
        document.querySelector('html').style.overflowY = null;
      })
      .catch(() => {
        document.querySelector('html').style.overflowY = null;
      });
  }

  protected detached() {
    this.pubsub.unsub();
  }

  protected context = 'service_invocing';
  protected filterClearAll: () => void;
  protected filterClearSingle: (name: string) => void;
  protected filterToggleVisible: () => void;
  protected filterGetFiltersQuery: () => Promise<{ serviceStationId?: number; customerIds?: string[] }>;
  protected setFilterValueByName: (name: string, data: any) => void;

  protected defaultFilters = {
    serviceStationId: true,
    customerIds: true,
  };

  protected getFilterKey() {
    return 'SERVICE_INVOICEABLE_TABLE';
  }

  protected onFilterChanged() {
    void this.getServices();
  }

  getFilterValues() {
    return getCallbackData(this.filterGetFiltersQuery);
  }

  protected async setupFilters() {
    return await new Promise((res: (v: Filters) => void) => {
      setTimeout(async () => {
        const serviceStationsJob = this.serviceStationService.getAll();
        const customersJob = this.customerService.getAllCached();

        const [customers, serviceStations] = await Promise.all([customersJob, serviceStationsJob]);
        const filteredServiceStations =
          serviceStations.sort((a, b) => (a.Name > b.Name ? 1 : -1)).filter((x) => x.CanGenerateInvoiceData) || [];
        const filteredCustomers = customers.filter((x) => !x.IsDeleted);

        const filters: Filters = {
          serviceStationId: {
            name: 'serviceStationId',
            label: this.i18n.tr('service.serviceStation'),
            type: filterTypes.RADIO,
            options: filteredServiceStations.map((x) => ({ Id: x.Id, Name: x.Name })),
          },
          customerIds: {
            name: 'customerIds',
            label: this.i18n.tr('general.customer'),
            type: filterTypes.CHECKBOX,
            options: filteredCustomers,
            query: 'customerIds',
          },
        };

        res(filters);
      });
    });
  }
}
